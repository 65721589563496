.Modal__Header {
  background-color: transparent;
  padding: 10px 0;
}

.Modal__Title {
  font-size: 18px;
  color: #565;
  font-weight: bold;
}

.shortened_link_header{
  font-size: 12px;
  color: #666;
  font-weight: normal;
  margin-left: 10px;
}