.LoginPage {
  height: 100vh;
  width: 100vw;
  background-color: #fff;
  position: relative;
}

.LoginForm,
.LoginForm > div {
  display: flex;
  flex-direction: column;
}

.LoginForm {
  width: 20vw;
  min-width: 250px;
  background-color: #fff;
  box-shadow: 0 0 20px 10px #effff5;
  padding: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #efe;
  border-radius: 8px;
}

.LoginForm__head{
    text-align: center;
    font-size: 27px;
    font-weight: bold;
    color: #565;
    margin-bottom: 25px;
}

.LoginForm__label {
  display: block;
  margin-bottom: 8px;
  margin-top: 0;
  color: #666;
  font-weight: bold;
}

.LoginForm__textarea,
.LoginForm__select,
.LoginForm__input {
  width: calc(100%);
  padding: 8px;
  margin-bottom: 14px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
  height: 40px;
  outline: none;
}

.error {
  border: 1px solid red !important;
}

.LoginForm__textarea {
  resize: none;
  height: 80px;
}

.LoginForm__button {
  background-color: #565;
  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 6px;
  font-weight: bold;
}

.LoginForm__button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.LoginForm__error, .LoginForm__error_generic {
  color: red;
}
.LoginForm__error{
    margin-top: 0;
    margin-bottom: 12px;    
}
.LoginForm__error_generic{
    margin-top: 0;
    margin-top: 5px;
}