.addform {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0px;
  grid-template-areas:
    "G1 G1 G1"
    "G2 G2 G2"
    "G3 G4 G5"
    "G6 G6 G6"
    "G7 G7 G7";
}

.addform > div {
  display: flex;
  flex-direction: column;
}

.addform > div:nth-child(1) {
  grid-area: G1;
}
.addform > div:nth-child(2) {
  grid-area: G2;
}
.addform > div:nth-child(3) {
  grid-area: G3;
}
.addform > div:nth-child(4) {
  grid-area: G4;
}
.addform > div:nth-child(5) {
  grid-area: G5;
}
.addform > div:nth-child(6) {
  grid-area: G6;
}
.addform > div:nth-child(7) {
  grid-area: G7;
}

.addform__label {
  display: block;
  margin-bottom: 8px;
  margin-top: 10px;
  color: #666;
  font-weight: bold;
  font-size: 14px;
}

.addform__textarea,
.addform__select,
.addform__input[type="text"] {
  width: calc(100% - 20px);
  padding: 8px;
  margin-bottom: 14px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
  height: 40px;
  outline: none;
}

.error {
  border-color: red;
}

.addform__textarea {
  resize: none;
  height: 80px;
}

.addform__button {
  background-color: #565;
  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 6px;
  font-weight: bold;
  width: 20%;
}

.addform__button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.addform__error {
  margin-top: 0;
  margin-bottom: 12px;
  color: red;
  min-height: 20px;
}
