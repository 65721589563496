.alertbody{
    font-size: 14px;
    margin-bottom: 10px;
}

.delete_ok_btn, .delete_cancel_btn{
    padding: 5px;
    font-size: 12px;
    font-weight: bold;
    min-width: 80px;
}

.delete_ok_btn{
    background-color: red;
    color: #fff;
}
.delete_ok_btn:hover{
    background-color: #dc0b0b;
    color: #fff;
}

.delete_cancel_btn{
    background-color: #ddd;
    color: #333;
}