.TopBar_container {
  padding-top: 10px;
  height: 55px;
}

.TopBar {
  display: grid;
  gap: 5px;
  grid-template-columns: 130px 70px 1fr 110px 55px;
  grid-template-areas: title add search button;
}

.Topbar_title {
  color: #333;
  font-size: 21px;
  font-weight: bold;
}

.Topbar_addlink{
    margin-top: -3px;
}

.Topbar_logoutlink {
    text-align: right;
    color: #333;
    margin: 5px 10px 0 0;
}

.Topbar_searchtext {
  color: #333;
  font-size: 16px;
}

.Topbar_searchtext_inputbox {
  margin-left: 15px;
  width: calc(100% - 200px);
  border: 1px solid #ddd;
  border-radius: 3px;
  outline: none;
  height: 30px;
  background: #fff;
  padding: 0 10px;
  color: #333;
}

.Topbar_buttonbox {
  text-align: right;
}

.Topbar_clearButton,
.Topbar_applyButton {
  padding: 7px 10px;
  font-weight: bold;
  box-shadow: none;
  border-radius: 5px;
  border: none;
}

.Topbar_clearButton {
  color: black;
  background-color: #ddd;
}

.Topbar_clearButton:active {
  background-color: #ccc;
}

.Topbar_applyButton {
  color: white;
  background-color: #565;
}

.Topbar_applyButton:active {
  background-color: #414f41;
}
